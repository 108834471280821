@import url('https://fonts.googleapis.com/icon?family=Material+Icons&display=swap');
@import url('https://fonts.googleapis.com/css?family=Inter:slnt,wght@-10..0,100..900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:slnt,wght@-10..0,100..900&display=swap');

.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

#Navigation a, #Footer a {
    text-decoration: none;
    color: white;
}


#Navigation a:hover, #Navigation a:active, #Navigation a:focus, #Footer a:hover, #Footer a:active, #Footer a:focus {
    text-decoration: underline;
}

.icon-border path:not([fill="none"]) {
    stroke: #646464;
    stroke-width: 2px;
}
