.circle {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle span {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 5px solid var(--amplify-components-button-primary-background-color);
    display: block;
    background-color: white;
    animation: 2s ease-in-out 0s infinite normal grow;
}

.circle:nth-child(2) span {
    animation-delay: .3s
}

.circle:nth-child(3) span {
    animation-delay: .6s
}

@keyframes grow {
    from {
        border-width: 5px;
    }
    50% {
        border-width: 12px;
    }
    from {
        border-width: 5px;
    }
}
