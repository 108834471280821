a:link {
    color: var(--amplify-colors-font-primary);
    text-decoration-color: blue;
}

a:visited *, a:visited {
    color: purple;
}

.clickedImage {
    border: 1px solid var(--amplify-colors-brand-primary-90) !important
}

.thumbnail:hover {
    cursor: pointer;
    border: 3px solid var(--amplify-components-button-primary-background-color) !important;
}
